import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  MATERIAL_SYMBOL_SIZE,
  MATERIAL_SYMBOL_NAMES,
} from 'consts/materialSymbols';
import Modal from 'components/Modal';
import { useTranslation } from 'react-i18next';
import MaterialSymbolIcon from 'components/MaterialSymbolIcon';
import Search from 'components/Search';
import Trie from 'utils/trie';
import { useMaterialSymbolsTranslations } from '../MaterialSymbolsTranslationProvider';
import styles from './index.less';

const MAX_DISPLAYED_ICONS = 50;

function IconPicker({ onSave, isVisible, onCancel }) {
  const { t, i18n } = useTranslation();
  const allTranslations = useMaterialSymbolsTranslations();
  const [selectedIcon, setSelectedIcon] = useState('');
  const [filterQuery, setFilterQuery] = useState('');
  const [filteredIconNames, setFilteredIconNames] = useState(
    MATERIAL_SYMBOL_NAMES.slice(0, MAX_DISPLAYED_ICONS),
  );

  // We decided to use Trie data structure to speed up the search
  // more info: https://en.wikipedia.org/wiki/Trie
  const trie = useMemo(() => {
    const trieInstance = new Trie();
    MATERIAL_SYMBOL_NAMES.forEach((name) => {
      trieInstance.insert(name, name);
    });
    Object.values(allTranslations).forEach((langTranslations) => {
      Object.entries(langTranslations).forEach(([translation, symbol]) => {
        trieInstance.insert(translation, symbol);
      });
    });
    return trieInstance;
  }, [allTranslations]);

  const handleIconClick = useCallback((iconName) => {
    setSelectedIcon(iconName);
  }, []);

  const handleSubmit = useCallback(() => {
    onSave(selectedIcon);
  }, [onSave, selectedIcon]);

  const handleFilterChange = useCallback(
    (e) => {
      const query = e.target.value;
      setFilterQuery(query);

      if (query === '') {
        setFilteredIconNames(
          MATERIAL_SYMBOL_NAMES.slice(0, MAX_DISPLAYED_ICONS),
        );
      } else {
        const results = trie.search(query);
        setFilteredIconNames(results.slice(0, MAX_DISPLAYED_ICONS));
      }
    },
    [trie],
  );

  const getIconLabel = useCallback(
    (iconName) => {
      const currentLangTranslations =
        allTranslations[i18n.language] || allTranslations.en;
      const translation = Object.entries(currentLangTranslations).find(
        ([, symbol]) => symbol === iconName,
      );
      return translation ? translation[0] : iconName;
    },
    [allTranslations, i18n.language],
  );

  return (
    <Modal
      visible={isVisible}
      maskClosable={false}
      title={t('IconPickerModal_title')}
      onCancel={onCancel}
      onOk={handleSubmit}
      okText={t('IconPickerModal_okText')}
    >
      <div className={styles.pickerContainer}>
        <div className={styles.selectedIcon}>
          <div>{t('IconPickerModal_selectedIcon')}</div>
          {selectedIcon && (
            <MaterialSymbolIcon
              value={selectedIcon}
              size={MATERIAL_SYMBOL_SIZE}
            />
          )}
        </div>
        <Search
          className={styles.searchInput}
          placeholder={t('IconPickerModal_searchPlaceholder')}
          onChange={handleFilterChange}
          value={filterQuery}
        />
        <div className={styles.iconsWrapper}>
          {filteredIconNames.map((iconName) => (
            <button
              type="button"
              key={iconName}
              className={styles.iconButton}
              onClick={() => handleIconClick(iconName)}
              title={getIconLabel(iconName)}
            >
              <MaterialSymbolIcon
                value={iconName}
                size={MATERIAL_SYMBOL_SIZE}
              />
            </button>
          ))}
        </div>
      </div>
    </Modal>
  );
}

IconPicker.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func,
  isVisible: PropTypes.bool,
};

export default IconPicker;
