import Button from 'components/Form/Button';
import React, { useCallback, useEffect } from 'react';
import { useForm, FormProvider, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Loader from 'components/Loader';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import { useParams, useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { validationSchema } from 'utils/validator';
import EditLandingPageMutation from 'pages/job-positions/JobOffers/editLandingPageMutation.gql';
import { useMutation } from '@apollo/client';
import { showError } from 'utils/message';
import { logAndShowGenericError } from 'utils/log';
import jobPositionDetailsQuery from 'pages/job-positions/JobPositionDetails/jobPositionDetailsQuery.gql';
import AboutUsBlock from './AboutUsBlock';
import AccordionBlock from './AccordionBlock';
import HeaderBlock from './HeaderBlock';
import IconListBlock from './IconListBlock';
import { MaterialSymbolsTranslationProvider } from './MaterialSymbolsTranslationProvider';

import styles from './JobOfferForm.less';
import ApplyBlock from './ApplyBlock';
import useLandingPage from './useLandingPage';
import useDefaultOfferValues from './useDefaultFormValues';

// Function to determine which block component to render
function renderBlockComponent(block, index) {
  const key = block.pk;
  if (block.accordionBlock) {
    return <AccordionBlock key={key} blockIndex={index} />;
  }

  if (block.iconListBlock) {
    return (
      <IconListBlock key={key} blockType="iconListBlock" blockIndex={index} />
    );
  }

  if (block.processBlock) {
    return (
      <IconListBlock key={key} blockType="processBlock" blockIndex={index} />
    );
  }

  if (block.aboutUsBlock) {
    return <AboutUsBlock key={key} blockIndex={index} />;
  }

  if (block.applyBlock) {
    return <ApplyBlock key={key} blockIndex={index} />;
  }

  return null;
}

function JobOfferForm() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [organizationPk] = useCurrentOrganization();
  const { offerPk, pk } = useParams();
  const { landingPageData, loading } = useLandingPage({
    landingPagePk: offerPk,
    organizationPk,
  });

  const defaultValues = useDefaultOfferValues(landingPageData);

  const [editLandingPage] = useMutation(EditLandingPageMutation);

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(validationSchema(t)),
  });

  const { fields } = useFieldArray({
    control: methods.control,
    name: 'blocks',
  });

  // defaultValues might be undefined when the form is initiated. We need to actualize methods on defaultValues change"
  useEffect(() => {
    methods.reset(defaultValues);
  }, [defaultValues, methods]);

  const onSubmit = useCallback(
    (data) => {
      editLandingPage({
        variables: {
          organizationPk,
          pk: offerPk,
          title: data.title,
          subtitle: data.subtitle,
          iconList: data.iconList,
          blocks: data.blocks,
        },
        refetchQueries: [
          {
            query: jobPositionDetailsQuery,
            variables: { organizationPk, pk },
          },
        ],
      })
        .then(({ data: responseData }) => {
          if (responseData.editLandingPage.errors) {
            return responseData.editLandingPage.errors.message
              ? showError(responseData.editLandingPage.errors.message)
              : logAndShowGenericError('editLandingPage rejected', {
                  organizationPk,
                  pk: offerPk,
                  title: responseData.title,
                  subtitle: responseData.subtitle,
                  iconList: responseData.iconList,
                  blocks: responseData.blocks,
                });
          }
          navigate(`/positions/${pk}/preview/${offerPk}`);
        })
        .catch(
          logAndShowGenericError('editLandingPage rejected', {
            organizationPk,
            pk: offerPk,
            title: data.title,
            subtitle: data.subtitle,
            iconList: data.iconList,
            blocks: data.blocks,
          }),
        );
    },
    [editLandingPage, navigate, offerPk, pk, organizationPk],
  );

  if (loading) {
    return <Loader />;
  }

  return (
    <MaterialSymbolsTranslationProvider>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <HeaderBlock />
          {fields.map((block, index) => renderBlockComponent(block, index))}
          <Button type="primary" htmlType="submit" className={styles.submitBtn}>
            {t('JobOfferForm_submit')}
          </Button>
        </form>
      </FormProvider>
    </MaterialSymbolsTranslationProvider>
  );
}

export default JobOfferForm;
