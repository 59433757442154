import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'components/Loader';
import { useTranslation } from 'i18n';
import { logAndShowGenericError } from 'utils/log';

const MaterialSymbolsTranslationContext = createContext();

export function MaterialSymbolsTranslationProvider({ children }) {
  const [materialSymbolsTranslations, setMaterialSymbolsTranslations] =
    useState(null);
  const { i18n } = useTranslation();

  useEffect(() => {
    const loadMaterialSymbolsTranslations = async () => {
      try {
        const [enTranslations, currentLangTranslations] = await Promise.all([
          import(
            /* webpackChunkName: "material-symbols-en" */ 'consts/materialSymbols.en'
          ),
          i18n.language !== 'en'
            ? import(
                /* webpackChunkName: "[request]" */ `consts/materialSymbols.${i18n.language}`
              ).catch(() => ({
                default: {},
              }))
            : Promise.resolve({ default: {} }),
        ]);

        setMaterialSymbolsTranslations({
          en: enTranslations.default,
          [i18n.language]: currentLangTranslations.default,
        });
      } catch (error) {
        logAndShowGenericError(
          'Failed to load material symbols translations:',
          error,
        );
        setMaterialSymbolsTranslations({ en: {}, [i18n.language]: {} });
      }
    };

    loadMaterialSymbolsTranslations();
  }, [i18n.language]);

  if (!materialSymbolsTranslations) {
    return <Loader fullSize />;
  }

  return (
    <MaterialSymbolsTranslationContext.Provider
      value={materialSymbolsTranslations}
    >
      {children}
    </MaterialSymbolsTranslationContext.Provider>
  );
}

MaterialSymbolsTranslationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useMaterialSymbolsTranslations = () =>
  useContext(MaterialSymbolsTranslationContext);
