/* eslint-disable max-classes-per-file */

export class TrieNode {
  constructor() {
    this.children = {};
    this.isEndOfWord = false;
    this.words = new Set();
  }

  addChild(char) {
    if (!this.children[char]) {
      this.children[char] = new TrieNode();
    }
    return this.children[char];
  }

  addWord(word) {
    this.words.add(word);
  }

  setEndOfWord() {
    this.isEndOfWord = true;
  }

  getChild(char) {
    return this.children[char];
  }

  getWords() {
    return Array.from(this.words);
  }
}

export default class Trie {
  constructor() {
    this.root = new TrieNode();
  }

  insert(word, original) {
    const lowerCaseWord = word.toLowerCase();
    const lastNode = lowerCaseWord.split('').reduce((node, char) => {
      const nextNode = node.addChild(char);
      nextNode.addWord(original);
      return nextNode;
    }, this.root);
    lastNode.setEndOfWord();
  }

  search(prefix) {
    const lowerCasePrefix = prefix.toLowerCase();
    const lastNode = lowerCasePrefix
      .split('')
      .reduce((node, char) => (node ? node.getChild(char) : null), this.root);

    return lastNode ? lastNode.getWords() : [];
  }
}
